<template>
  <b-link
    :to="getToObject()"
    :target="target"
  >
    <feather-icon
      size="12"
      :icon="icon"
    />
    {{ text }}
  </b-link>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  props: {
    to: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      default: null,
    },
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      icon: '',
    }
  },
  methods: {
    getToObject() {
      const to = {
        name: this.to,
        params: null,
      }
      if (this.type === 'driver') {
        to.params = {
          did: this.id,
        }
        this.icon = 'UserIcon'
      } else if (this.type === 'race') {
        to.params = {
          rid: this.id,
        }
        this.icon = 'ClipboardIcon'
      } else if (this.type === 'series') {
        to.params = {
          sid: this.id,
        }
        this.icon = 'LayersIcon'
      } else if (this.type === 'track') {
        to.params = {
          tid: this.id,
        }
        this.icon = 'MapPinIcon'
      }
      return to
    },
  },
}
</script>
