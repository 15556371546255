<template>
  <div>
    <TrackInfo
      :ptid="currentTrackId"
      :ptrackinfo="trackInfo"
    />
    <TrackRecords
      :ptid="currentTrackId"
    />
    <TrackHistory
      :ptid="currentTrackId"
    />
  </div>
</template>

<script>
import Vue from 'vue'
// import { BRow, BCol } from 'bootstrap-vue'
import axios from '@axios'
import TrackInfo from './trackDetailsInfo.vue'
import TrackRecords from './trackDetailsRecords.vue'
import TrackHistory from './trackDetailsHistory.vue'

export default {
  components: {
    // BRow, BCol,
    TrackInfo, TrackRecords, TrackHistory,
  },
  data() {
    return {
      currentTrackId: 0,
      trackInfo: [],
    }
  },
  created() {
    this.currentTrackId = Number(this.$router.currentRoute.params.tid)
    axios.get(`${Vue.prototype.$Api_Track_Info}${this.currentTrackId}`)
      .then(response => {
        this.trackInfo = response.data
      })
  },
}
</script>
