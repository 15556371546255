<template>
  <b-card-code
    title="赛道记录"
    no-body
  >
    <b-table
      :fields="fields"
      :items="items"
      responsive
      class="mb-0"
    >

      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(BestTime)="data">
        {{ toLapTime(data.item.BestTime) }}
      </template>

      <template #cell()="data">
        {{ data.value }}
      </template>
    </b-table>

  </b-card-code>
</template>

<script>
import Vue from 'vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable } from 'bootstrap-vue'
import axios from '@axios'

export default {
  components: {
    BCardCode,
    BTable,
  },
  props: {
    ptid: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: 'index', label: '#' },
        { key: 'Class', label: '组别' },
        { key: 'BestVeh', label: '车型' },
        { key: 'BestDriver', label: '车手' },
        { key: 'BestTime', label: '赛道记录' },
        { key: 'Platform', label: '平台' },
        { key: 'RaceDate', label: '日期' },
      ],
      items: [],
    }
  },
  created() {
    axios.get(`${Vue.prototype.$Api_Track_Records}${this.ptid}`)
      .then(response => {
        this.items = response.data
      })
  },
  methods: {
    toLapTime(value) {
      return this.$convertToLapTime(value)
    },
  },
}
</script>
