<template>
  <b-row class="match-height">

    <!-- 赛道图片 -->
    <b-col lg="3">
      <b-card>
        <b-img
          v-b-modal.modal-track
          fluid
          :src="require(`@/assets/images/tracks/T${currentTrackId}.png`)"
        />
      </b-card>
    </b-col>
    <b-modal
      id="modal-track"
      :hide-footer="true"
      centered
      size="lg"
      :title="trackName"
    >
      <b-img
        v-b-modal.modal-track
        fluid
        :src="require(`@/assets/images/tracks/T${currentTrackId}.png`)"
      />
    </b-modal>

    <!-- 赛道基础信息表格 -->
    <b-col lg="6">
      <b-card-code no-body>
        <b-card-body>
          <b-table
            v-if="trackInfo.length > 0"
            stacked
            :items="trackInfo"
            :fields="fields"
          >
            <template #cell(TrackNameEN)="data">
              {{ getTrackName(data.item) }}
            </template>

            <template #cell(Continent)="data">
              {{ getTrackLocation(data.item) }}
            </template>

            <template #cell()="data">
              {{ data.value }}
            </template>
          </b-table>
        </b-card-body>
      </b-card-code>
    </b-col>

    <!-- 赛道历史表格 -->
    <b-col lg="3">
      <b-card-code no-body>
        <b-card-body>
          <b-table
            v-if="trackInfo.length > 0"
            stacked
            :items="trackInfo"
            :fields="fieldsNum"
          >
            <template #cell(Length)="data">
              {{ data.item.Length }}米
            </template>

            <template #cell(RaceCount)="data">
              {{ data.item.RaceCount }}场
            </template>

            <template #cell()="data">
              {{ data.value }}
            </template>
          </b-table>
        </b-card-body>
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BCardBody, BTable, BCard, BImg, BModal, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow, BCol, BCardBody, BCardCode, BTable, BCard, BImg, BModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    ptid: {
      type: Number,
      required: true,
    },
    ptrackinfo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentTrackId: this.ptid,
      fields: [
        { key: 'TrackNameEN', label: '赛道全名' },
        { key: 'TrackNameZH', label: '中文名称' },
        { key: 'TrackLayout', label: '赛道布局', formatter: this.getLayout },
        { key: 'Continent', label: '赛道地点' },
      ],
      fieldsNum: [
        { key: 'Length', label: '赛道长度' },
        { key: 'LeftCorners', label: '左弯' },
        { key: 'RightCorners', label: '右弯' },
        { key: 'RaceCount', label: '举办赛事' },
      ],
      trackInfo: this.ptrackinfo,
      trackName: '',
    }
  },
  watch: {
    ptrackinfo: {
      deep: true,
      handler(newVal) {
        this.trackInfo = newVal
      },
    },
  },
  methods: {
    getTrackName(item) {
      this.trackName = `${item.TrackNameEN}（${item.TrackNameAbbr}）`
      return this.trackName
    },
    getLayout(value) {
      let layout = value
      if (layout === '') {
        layout = '全长布局'
      }
      return layout
    },
    getTrackLocation(item) {
      return `${item.Country}, ${item.Continent}`
    },
  },
}
</script>
